import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, tap, timeout, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { IArtifact } from 'app/models/artifact';
import * as deepmerge from 'deepmerge';

@Injectable({
    providedIn: 'root'
})
export class ArtifactService {

    constructor(private readonly httpClient: HttpClient) { }

    public async getArtifacts(): Promise<IArtifact[]> {
        const url = environment.artifactLocation + 'artifacts.json';

        return this.httpClient.get<IArtifact[]>(url, {
            headers: {
                'Accept': 'application/json',
                'Cache-Control': 'no-cache',
                //'timeout': this.timeout.toString(),
            },
            //params: params
        }).pipe(
            //timeout(this.timeout),
            catchError(error => {
                if (error && error.name === 'TimeoutError') throw new HttpErrorResponse({
                    url: url,
                    status: 408,
                    statusText: error.message
                });

                throw error;
            }),
            //tap(response => ResourceService.validateInQdoResponse(url, response)),

            map(ArtifactService.resolveLocations)
        ).toPromise();
    }

    private static resolveLocations(artifacts: IArtifact[]): IArtifact[] {
        artifacts = deepmerge([], artifacts); // seep clone artifacts

        for (let artifact of artifacts) {
            const prefix = environment.artifactLocation + artifact.id + '/';

            artifact.icon = prefix + artifact.icon;
            artifact.manifest = prefix + artifact.manifest;
            artifact.mobileconfig = prefix + artifact.mobileconfig;

            for (let index = 0; index < artifact.screenshots.length; index++) {
                artifact.screenshots[index] = prefix + artifact.screenshots[index];
            }
        }

        // console.log(artifacts);

        return artifacts;
    }


}
